<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <div class="p-5">
            <div class="buttons btn-group">
              <button
                :class="tabs == 1 ? 'btn btn-primary btn-lg active' : ' btn btn-primary btn-lg'"
                @click="
                  tabs = 1;
                  type = 'nnomina';
                  getData();
                  clearVarFiles();
                "
                type="button"
              >
                <b> Novedades nómina</b>
              </button>

              <button
                :class="tabs == 2 ? 'btn btn-primary btn-lg active' : ' btn btn-primary btn-lg'"
                @click="
                  tabs = 2;
                  type = 'docseguimiento';
                  getData();
                  clearVarFiles();
                "
                type="button"
              >
                <b> Docs Seguimiento</b>
              </button>

              <button
                :class="tabs == 3 ? 'btn btn-primary btn-lg active' : ' btn btn-primary btn-lg'"
                @click="
                  tabs = 3;
                  type = 'docprevioingreso';
                  getData();
                  clearVarFiles();
                "
                type="button"
              >
                <b> Docs Previo ingreso</b>
              </button>

              <button
                :class="tabs == 4 ? 'btn btn-primary btn-lg active' : ' btn btn-primary btn-lg'"
                @click="
                  tabs = 4;
                  type = 'docIngreso';
                  getData();
                  clearVarFiles();
                "
                type="button"
              >
                <b> Docs Ingreso</b>
              </button>

              <button
                :class="tabs == 5 ? 'btn btn-primary btn-lg active' : ' btn btn-primary btn-lg'"
                @click="
                  tabs = 5;
                  type = 'docContrata';
                  getData();
                  clearVarFiles();
                "
                type="button"
              >
                <b> Docs Contratación</b>
              </button>
            </div>

            <div v-if="tabs == 1">
              <div class="table-responsive px-4 mt-3">
                <table id="datatable_persona_historial_laboral" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">Documentos (Colaborador)</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Vigente</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom" v-for="it of items" :key="it.id">
                      <td class="">
                        <span class=""> {{ it.NombreOriginal }} </span>
                      </td>
                      <td class="">
                        <span class=""> {{ $moment.tz(it.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm")  }} </span>
                      </td>
                      <td class="">
                        <span class=""> {{ it.Vigente == true ? "Si" : "No" }} </span>
                      </td>
                      <td class="h4 text-center">
                        <span class="px-1">
                          <i class="fas fa-eye text-primary text-warning" @click="gotoFile(it)"></i>
                          <!-- <font-awesome-icon @click="gotoFile(it)" class="text-primary text-warning" icon="eye" /> -->
                        </span>
                        <span class="px-1">
                          <i class="fas fa-download text-primary text-success" @click="gotoFile(it)"></i>
                        </span>
                        <!-- <span class="px-1">
                          <i class="fas fa-trash text-primary text-secondary" @click="gotoFile(it)"></i>
                          <font-awesome-icon @click="removeFile(it)" class="text-primary text-danger" icon="trash" />
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row py-3">
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text rounded-left" id="basic-addon1"><font-awesome-icon class="text-primary text-primary" icon="magnifying-glass" /></span>
                    <input type="text" class="form-control rounded-right" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                </div>            
              </div> -->
                <div class="bg-dark pb-5" @dragover="dragover" @dragleave="dragleave" @drop="drop" style="min-height: 80px">
                  <div class="text-center"><img class="img-fluid" src="~@/assets/images/nube.png" alt="" /> <br /></div>
                  <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                    <div class="p-12">
                      <input
                        hidden
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                      />

                      <label for="assetsFieldHandle" class="block btn btn-secondary"> <div>Arrastrar y soltar archivos aqui</div> </label><br />
                      <input v-if="this.filelist.length" @click="onFileSelected2('1')" type="button" value="Subir archivos" />
                      <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                        <li class="text-left p-1" v-for="file in filelist" :key="file.name">
                          {{ file.name }}<button class="ml-2 btn-secondary" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">Eliminar</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <br /><br />
                <!-- <div class="py-3">
                <br />
                <input multiple type="file" id="file1" ref="file1" v-on:change="onFileSelected()" /><br />
              </div> -->
              </div>
            </div>
            <div v-if="tabs == 2">
              <div class="table-responsive px-4 mt-3">
                <table id="datatable_persona_historial_laboral" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">Documentos (Colaborador)</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Vigente</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom" v-for="it of items" :key="it.id">
                      <td class="">
                        <span class=""> {{ it.NombreOriginal }} </span>
                      </td>
                      <td class="">
                        <span class="">  {{ $moment.tz(it.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm")  }}  </span>
                      </td>
                      <td class="">
                        <span class=""> {{ it.Vigente == true ? "Si" : "No" }} </span>
                      </td>
                      <td class="h4 text-center">
                        <span class="px-1">
                          <i class="fas fa-eye text-primary text-warning" @click="gotoFile(it)"></i>
                          <!-- <font-awesome-icon @click="gotoFile(it)" class="text-primary text-warning" icon="eye" /> -->
                        </span>
                        <span class="px-1">
                          <i class="fas fa-download text-primary text-success" @click="gotoFile(it)"></i>
                        </span>
                        <!-- <span class="px-1">
                          <font-awesome-icon @click="removeFile(it)" class="text-primary text-danger" icon="trash" />
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row py-3">
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text rounded-left" id="basic-addon1"><font-awesome-icon class="text-primary text-primary" icon="magnifying-glass" /></span>
                    <input type="text" class="form-control rounded-right" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                </div>            
              </div> -->
                <div class="bg-dark pb-5" @dragover="dragover" @dragleave="dragleave" @drop="drop" style="min-height: 80px">
                  <div class="text-center"><img class="img-fluid" src="~@/assets/images/nube.png" alt="" /> <br /></div>
                  <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                    <div class="p-12">
                      <input
                        hidden
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                      />

                      <label for="assetsFieldHandle" class="block btn btn-secondary"> <div>Arrastrar y soltar archivos aqui</div> </label><br />
                      <input v-if="this.filelist.length" @click="onFileSelected2('2')" type="button" value="Subir archivos" />
                      <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                        <li class="text-left p-1" v-for="file in filelist" :key="file.name">
                          {{ file.name }}<button class="ml-2 btn-secondary" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">Eliminar</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <br /><br />
              </div>
            </div>
            <div v-if="tabs == 3">
              <div class="table-responsive px-4 mt-3">
                <table id="datatable_persona_historial_laboral" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">Documentos (Colaborador)</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Vigente</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom" v-for="it of items" :key="it.id">
                      <td class="">
                        <span class=""> {{ it.NombreOriginal }} </span>
                      </td>
                      <td class="">
                        <span class=""> {{ $moment.tz(it.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm")  }}  </span>
                      </td>
                      <td class="">
                        <span class=""> {{ it.Vigente == true ? "Si" : "No" }} </span>
                      </td>
                      <td class="h4 text-center">
                        <span class="px-1">
                          <i class="fas fa-eye text-primary text-warning" @click="gotoFile(it)"></i>
                          <!-- <font-awesome-icon @click="gotoFile(it)" class="text-primary text-warning" icon="eye" /> -->
                        </span>
                        <span class="px-1">
                          <i class="fas fa-download text-primary text-success" @click="gotoFile(it)"></i>
                        </span>
                        <!-- <span class="px-1">
                          <font-awesome-icon @click="removeFile(it)" class="text-primary text-danger" icon="trash" />
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row py-3">
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text rounded-left" id="basic-addon1"><font-awesome-icon class="text-primary text-primary" icon="magnifying-glass" /></span>
                    <input type="text" class="form-control rounded-right" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                </div>            
              
              </div> -->
                <div class="bg-dark pb-5" @dragover="dragover" @dragleave="dragleave" @drop="drop" style="min-height: 80px">
                  <div class="text-center"><img class="img-fluid" src="~@/assets/images/nube.png" alt="" /> <br /></div>
                  <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                    <div class="p-12">
                      <input
                        hidden
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                      />

                      <label for="assetsFieldHandle" class="block btn btn-secondary"> <div>Arrastrar y soltar archivos aqui</div> </label><br />
                      <input v-if="this.filelist.length" @click="onFileSelected2('3')" type="button" value="Subir archivos" />
                      <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                        <li class="text-left p-1" v-for="file in filelist" :key="file.name">
                          {{ file.name }}<button class="ml-2 btn-secondary" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">Eliminar</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <br /><br />
              </div>
            </div>
            <div v-if="tabs == 4">
              <div class="table-responsive px-4 mt-3">
                <table id="datatable_persona_historial_laboral" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">Documentos (Colaborador)</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Vigente</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom" v-for="it of items" :key="it.id">
                      <td class="">
                        <span class=""> {{ it.NombreOriginal }} </span>
                      </td>
                      <td class="">
                        <span class=""> {{ $moment.tz(it.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm")  }}  </span>
                      </td>
                      <td class="">
                        <span class=""> {{ it.Vigente == true ? "Si" : "No" }} </span>
                      </td>
                      <td class="h4 text-center">
                        <span class="px-1">
                          <i class="fas fa-eye text-primary text-warning" @click="gotoFile(it)"></i>
                          <!-- <font-awesome-icon @click="gotoFile(it)" class="text-primary text-warning" icon="eye" /> -->
                        </span>
                        <span class="px-1">
                          <i class="fas fa-download text-primary text-success" @click="gotoFile(it)"></i>
                        </span>
                        <!-- <span class="px-1">
                          <font-awesome-icon @click="removeFile(it)" class="text-primary text-danger" icon="trash" />
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row py-3">
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text rounded-left" id="basic-addon1"><font-awesome-icon class="text-primary text-primary" icon="magnifying-glass" /></span>
                    <input type="text" class="form-control rounded-right" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                </div>            
              </div> -->
                <div class="bg-dark pb-5" @dragover="dragover" @dragleave="dragleave" @drop="drop" style="min-height: 80px">
                  <div class="text-center"><img class="img-fluid" src="~@/assets/images/nube.png" alt="" /> <br /></div>
                  <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                    <div class="p-12">
                      <input
                        hidden
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                      />

                      <label for="assetsFieldHandle" class="block btn btn-secondary"> <div>Arrastrar y soltar archivos aqui</div> </label><br />
                      <input v-if="this.filelist.length" @click="onFileSelected2('4')" type="button" value="Subir archivos" />
                      <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                        <li class="text-left p-1" v-for="file in filelist" :key="file.name">
                          {{ file.name }}<button class="ml-2 btn-secondary" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">Eliminar</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <br /><br />
              </div>
            </div>
            <div v-if="tabs == 5">
              <div class="table-responsive px-4 mt-3">
                <table id="datatable_persona_historial_laboral" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">Documentos (Colaborador)</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Vigente</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom" v-for="it of items" :key="it.id">
                      <td class="">
                        <span class=""> {{ it.NombreOriginal }} </span>
                      </td>
                      <td class="">
                        <span class="">  {{ $moment.tz(it.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm")  }}  </span>
                      </td>
                      <td class="">
                        <span class=""> {{ it.Vigente == true ? "Si" : "No" }} </span>
                      </td>
                      <td class="h4 text-center">
                        <span class="px-1">
                          <i class="fas fa-eye text-primary text-warning" @click="gotoFile(it)"></i>
                          <!-- <font-awesome-icon @click="gotoFile(it)" class="text-primary text-warning" icon="eye" /> -->
                        </span>
                        <span class="px-1">
                          <i class="fas fa-download text-primary text-success" @click="gotoFile(it)"></i>
                        </span>
                        <!-- <span class="px-1">
                          <font-awesome-icon @click="removeFile(it)" class="text-primary text-danger" icon="trash" />
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row py-3">
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text rounded-left" id="basic-addon1"><font-awesome-icon class="text-primary text-primary" icon="magnifying-glass" /></span>
                    <input type="text" class="form-control rounded-right" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                </div>            
              </div> -->
                <div class="bg-dark pb-5" @dragover="dragover" @dragleave="dragleave" @drop="drop" style="min-height: 80px">
                  <div class="text-center"><img class="img-fluid" src="~@/assets/images/nube.png" alt="" /> <br /></div>
                  <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                    <div class="p-12">
                      <input
                        hidden
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                      />

                      <label for="assetsFieldHandle" class="block btn btn-secondary"> <div>Arrastrar y soltar archivos aqui</div> </label><br />
                      <input v-if="this.filelist.length" @click="onFileSelected2('5')" type="button" value="Subir archivos" />
                      <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                        <li class="text-left p-1" v-for="file in filelist" :key="file.name">
                          {{ file.name }}<button class="ml-2 btn-secondary" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">Eliminar</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <br /><br />
              </div>
            </div>
          </div>
          <!-- Button trigger modal -->
          <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Launch demo modal</button> -->

          <!-- Modal -->
          <div v-if="OpenClose" class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style="display: block">
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" @click="closeModal()" class="btn-close">X</button>
                </div>
                <div class="modal-body" style="height: 500px !important">
                  <iframe width="100%" height="100%" :src="urlFile+'#toolbar=0'" frameborder="0"></iframe>
                  <!-- <pdf :src="urlFile" /> -->
                  <!-- <button type="button mt-3" @click="closeModal()">cerrar</button> -->
                </div>
                <div class="modal-footer"></div>
              </div>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from "../../../config/pluginInit";

import Swal from "sweetalert2";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import PDFViewer from "pdf-viewer-vue";
//import VuePdfEmbed from 'vue-pdf-embed'
 //import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
//  import pdf from 'vue-pdf'



export default {
  // components: { pdf },
  name: "DataTable",
  // components: { FontAwesomeIcon },
  data() {
    return {
      tableName: "projects",
      sidebar_on_off: false,
      id: "",
      items: [{ id: 1, NombreOriginal: "cv.pdf", Fecha: "2023/02/15", Vigente: true }],
      item: {},
      tabs: "1",
      state_client_freelancer: "1",
      cardOrigin: "detailProject",
      user: {},

      file1: null,
      finename1: null,
      documento: "",
      type: "nnomina",
      superAdmin: false,
      visible: true,
      files: [],
      filelist: [],
      urlFile: "",
      OpenClose: false,
      realName: "",
      persona: {},
      pdfpath:"",
      pdfFile:""
    };
  },
  async mounted() {
    document.oncontextmenu = ev =>{
    ev.preventDefault();
    console.log("Prevented to open menu!");
}
    //this.renameFile('20221103 ó Ó Á a IÍÓÚ.pdf')
    core.index();
    window.$("#datatable_persona_historial_laboral").DataTable();
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));

    this.id = this.$route.params.id;
    this.documento = this.$route.params.document;
    this.getData();
    this.visible = true;
    // Swal.fire({
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,

    //   title: "Ingresa tu número de documento",
    //   input: "text",
    //   inputAttributes: {
    //     autocapitalize: "off",
    //   },
    //   confirmButtonText: "Verificar",
    //   cancelButtonText: "Cancelar",
    //   showLoaderOnConfirm: true,
    //   showCancelButton: false,
    //   preConfirm: async (login) => {
    //     //console.log(login);
    //     this.documento = login;
    //     let ress = await this.verifyUser();
    //     //console.log("ress", ress);
    //     if (ress == true) {
    //       this.getData();
    //       this.visible = true;
    //     } else {
    //       //alert("Error, usuario no válido.");
    //       this.visible = false;
    //       // Swal.fire({
    //       //   title: `Error, usuario no válido`,
    //       // });
    //       location.reload();
    //     }
    //   },
    //   // allowOutsideClick: () => !Swal.isLoading(),
    // });

    //console.log("tespues de la pregunta...");
    // if(this.visible == false){
    //   Swal.fire({
    //   title: "Ingresa tu número de documento",
    //   input: "text",
    //   inputAttributes: {
    //     autocapitalize: "off",
    //   },
    //   showCancelButton: true,
    //   confirmButtonText: "Verificar",
    //   cancelButtonText: "Cancelar",
    //   showLoaderOnConfirm: true,
    //   preConfirm: async (login) => {
    //     //console.log(login);
    //     this.documento = login;
    //     let ress = await this.verifyUser();
    //     //console.log("ress", ress);
    //     if (ress == true) {
    //       this.getData();
    //       this.visible = true;
    //     } else {
    //       //alert("Error, usuario no válido.");
    //       this.visible = false;
    //       Swal.fire({
    //         title: `Error, usuario no válido`,
    //       });
    //     }
    //   },

    //   allowOutsideClick: () => !Swal.isLoading(),
    // });
    // }
    // this.user = JSON.parse(localStorage.getItem('user'))
  },
  methods: {
    clearVarFiles() {
      this.file1 = null;
      this.finename1 = null;
      this.files = [];
      this.filelist = [];
    },
    async removeFile(item) {
      try {
        Swal.fire({
          title: "Seguro eliminar archivo?",
          text: "Después de eliminarlo no podrás recuperarlo!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            //let loader = this.$loading.show();
            await this.$store.dispatch("delete", { path: "users/removeFile/" + item.Id });

            //console.log("res", res);
            //loader.hide()
            await this.getData();

            Swal.fire("Listo!", "Archivo eliminado correctamente.", "success");
          }
        });
      } catch (error) {
        //loader.hide()
        //console.log("error", error);
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      //console.log(event.dataTransfer.files.length);
      this.files = event;

      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    closeModal() {
      this.OpenClose = false;
    },
    gotoFile(it) {
      // var myModal = new bootstrap.Modal(document.getElementById('myModal'), options)

      // var modalToggle = document.getElementById('toggleMyModal') // relatedTarget
      // myModal.show(modalToggle)
      if (it.Nombre.includes(".pdf")) {
        this.OpenClose = true;
        this.pdfFile = it.Nombre
        this.pdfpath="https://www.helexiumfiles.com/users/" +
          (it.TipoArchivo == "nnomina"
            ? "1"
            : it.TipoArchivo == "docseguimiento"
            ? "2"
            : it.TipoArchivo == "docprevioingreso"
            ? "3"
            : it.TipoArchivo == "docIngreso"
            ? "4"
            : it.TipoArchivo == "docContrata"
            ? "5"
            : "----") +
          "/" 


        this.urlFile =
          "https://www.helexiumfiles.com/users/" +
          (it.TipoArchivo == "nnomina"
            ? "1"
            : it.TipoArchivo == "docseguimiento"
            ? "2"
            : it.TipoArchivo == "docprevioingreso"
            ? "3"
            : it.TipoArchivo == "docIngreso"
            ? "4"
            : it.TipoArchivo == "docContrata"
            ? "5"
            : "----") +
          "/" +
          it.Nombre;
        //this.urlFile = "https://www.helexiumfiles.com/users/1/54E91870-E5A3-4777-A2A6-00126F64FCEE11.pdf"
        console.log(this.urlFile, it);
      } else {
        window.open(
          "https://www.helexiumfiles.com/users/" +
            (it.TipoArchivo == "nnomina"
              ? "1"
              : it.TipoArchivo == "docseguimiento"
              ? "2"
              : it.TipoArchivo == "docprevioingreso"
              ? "3"
              : it.TipoArchivo == "docIngreso"
              ? "4"
              : it.TipoArchivo == "docContrata"
              ? "5"
              : "----") +
            "/" +
            it.Nombre,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500"
        );
      }
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async onFileSelected2(type) {
      //this.files = this.filelist
      //console.log("...", this.filelist);
      for await (let it of this.filelist) {
        //console.log(it);
        this.$isLoading(true);

        try {
          this.file1 = it; //event.target.files[0];
          this.filename1 = await this.renameFile(this.file1.name); // Math.floor(Math.random() * 9999999999999 + 1);
          //console.log("a validar filename", this.filename1);
          if (this.filename1 != null) {
            //console.log("a realizar consulta");
            let uploadImg = await this.$store.dispatch("onFileSelectedHistoriaL", { filename: this.filename1, type: type, userId: this.id, file: this.file1 });
            //console.log("???", uploadImg);
            if (uploadImg == true) {
              let data = {
                PersonaId: this.id,
                Nombre: this.id + "_" + this.filename1,
                NombreOriginal: this.realName,
                Ruta: "ruta",
                TipoArchivo: this.type,
              };
              await this.$store.dispatch("postw", { path: "personaArchivos/new", data: data });
              this.getData();
              this.clearVarFiles();
              //console.log(res);
            }
          } else {
            //console.log("nel");
          }
          this.$isLoading(true);
        } catch (error) {
          //console.log("errror upload", error);
          this.$isLoading(true);
        }
      }
    },

    logout() {
      this.$store.dispatch("closeSession");
    },
    login() {
      this.$store.dispatch("startSession");
    },

    async getData() {
      this.$isLoading(true);

      try {
        let res = await this.$store.dispatch("getw", { path: "users/getFilesById/" + this.id + "/" + this.type });
        this.$isLoading(false);

        //console.log("------>", res);
        if (res.length > 0) {
          this.items = res;
          ////console.log(res)
        } else {
          this.items = [];
        }
      } catch (error) {
        this.$isLoading(false);
        this.items = [];
        //console.log("err", error);
      }
    },

  },
};
</script>

<style>
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  border-radius: 11px;
}
.buttons > * {
  border-radius: 0;
}
.validacionEnvio {
  margin: 1rem 0;
}
</style>
